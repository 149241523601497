import React from "react"
import { Link, graphql, useStaticQuery, PageRendererProps } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          slug={this.props.location.pathname}
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div className="container">
          <article>
            <div>
              <h1>
                {post.frontmatter.title}
              </h1>
              <div className="profile">
                <img className="profile-pic" src="https://miro.medium.com/fit/c/96/96/2*zjw5NvsgZjf10xTzOdNSrg.png" />
                <div className="profile-info">
                  <div className="author">
                    {post.frontmatter.author}
                  </div>
                  <div className="date">
                    {post.frontmatter.date}
                  </div>
                </div>
              </div>
            </div>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </article>

          <nav>
            {(previous || next) && <div><hr /> <h4>KEEP READING</h4></div>}
            <ul>
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title}
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
          site {
          siteMetadata {
          title
        }
        }
    markdownRemark(fields: {slug: {eq: $slug } }) {
          id
      excerpt(pruneLength: 160)
              html
      frontmatter {
          title
        author
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
